


/* Freeform default Flexbox CSS */
button[type=submit].ff-loading {
	display: inline-flex;
	flex-wrap: nowrap;
	align-items: center;
}

button[type=submit].ff-loading::before {
	content: '';
	display: block;
	flex: 1 0 11px;

	width: 11px;
	height: 11px;
	margin-right: 10px;

	border-style: solid;
	border-width: 2px;
	border-color: transparent transparent white white;
	border-radius: 50%;
	animation: ff-loading .5s linear infinite;
}

@keyframes ff-loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}


.freeform {
	--errorColor: #721c24;
	--gutter: 15px;
}

.freeform-pages {
	display: flex;
	padding: 0;
	margin: 0 0 10px;
	list-style: none;

}

.freeform-pages li {
	margin: 0 10px 0 0;
}

.freeform-row {
	justify-content: space-between;
	margin: 0 calc(-1 * var(--gutter));
}

.freeform-column {
	flex: 1 0;
	padding: 10px 0;
	margin: 0 var(--gutter);

	box-sizing: border-box;
}

.freeform-column > .freeform-row:first-child {
	margin-top: -10px;
}

.freeform-column label {
	display: block;
	margin-bottom: 0.3333em;
}

.freeform-column .input-group-one-line {
	display: flex;
	flex-wrap: wrap;
}

.freeform-column .input-group-one-line label {
	padding-right: 10px;
}

.freeform-required::after {
	content: "*";
	margin-left: 5px;
	color: red;
}

.freeform-input {
	width: 100%;
	display: block;
	box-sizing: border-box;
	font-family: inherit;
	font-weight: inherit;
}

select.freeform-input,
.freeform-input::file-selector-button,
.freeform-input-submit {
	font-family: inherit;
	font-weight: inherit;
	cursor: pointer;
}

.freeform-input[type=checkbox],
.freeform-input[type=radio] {
	display: inline;
	width: auto;
	margin-right: 5px;
}

.freeform-input[type=email],
.freeform-input[type=number],
.freeform-input[type=search],
.freeform-input[type=tel],
.freeform-input[type=text],
.freeform-input[type=url],
.freeform-input[type=file]::file-selector-button,
textarea.freeform-input,
select.freeform-input {
	padding: 2px 4px;
	border: var(--border);
	background: var(--white);
}

.freeform-input-submit {
	display: inline-block;
	box-sizing: border-box;
	line-height: 1.5;
	color: var(--white);
	background: var(--black);
	border: var(--border);
	-webkit-appearance: none;
}

.freeform-input[type=file]::file-selector-button,
.freeform-input-submit {
	padding: 2px 12px;
	margin-right: 5px;
}

.freeform-input-submit::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.freeform-input.StripeElement {
	padding: 4px 2px;
	border: 1px solid #CCCCCC;
	height: 30px;
}

.freeform-input-only-label {
	font-weight: normal;
}

.freeform-input-only-label > .freeform-input {
	display: inline-block;
	width: auto;
	margin-right: 5px;
}


.freeform-input-only-label > .freeform-input {
	display: inline-block;
	width: auto;
	margin-right: 5px;
}

.freeform-errors, 
.ff-errors {
	list-style: none;
	padding: 0;
	margin: 5px 0 0;
}

.freeform-errors > li, 
.ff-errors > li {
		color: red;
}

.freeform-instructions {
	margin:  0 0 5px;
	font-size: 13px;
	color: #ABA7A7;
}

.freefrom-colum[class*="freeform-column-content-align"] {
	display: flex;
}

.freefrom-colum[class*="freeform-column-content-align"] > button:not(:first-of-type) {
	margin-left: 5px;
}

.freeform-column.freeform-column-content-align-left {
	justify-content: flex-start;
}

.freeform-column.freeform-column-content-align-center {
	justify-content: flex-center;
}

.freeform-column.freeform-column-content-align-right {
	justify-content: flex-end;
}

.freeform-column.freeform-column-content-align-spread {
	justify-content: space-between;
}

.ff-form-errors {
	padding: 15px;

	border: 1px solid #f5c6cb;
	background: #f8d7da;
	border-radius: 5px;

	color: var(--errorColor1);

}

.ff-form-errors > p {
	margin: 0;
}

.freeform-form-has-errors {
	color: var(--errorColor);
}

@media (--medium-viewport) {
	.freeform-row {
		display: flex;
	}
}
