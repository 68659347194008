figure {
  margin: 0;
}

p + figure,
ul + figure,
ol + figure {
  margin: 0.75em 0 0;
}

.teaser-image,
.hero-image,
.carousel-image {
  display: block;
  width: 100%;
  height: auto;
}

.hero-image,
.carousel-image {
  height: 80vh;
  max-height: 800px;
}

.column-image-wrapper {
  margin: 15px 0;
}

.column-image {
  display: block;
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  object-position: 0 0;
}

.image-cover {
  object-fit: cover;
}

.image-contain {
  object-fit: contain;
}

.image-v-center {
  object-position: 50% 50%;
}

.image-v-bottom {
  object-position: 50% 100%;
}

.ig-image-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 100%;
  background: black;
}

.ig-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  transition: transform .25s;
}

.no-touch .ig-image:hover {
  transform: scale(1.25);
}

.news-teaser-wrapper {
  display: none;
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin-top: 0.5rem;
}

.news-teaser-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 0%;
}

.redactor img {
  display: block;
  max-width: 100%;
  height: auto;
}

@media (--medium-viewport) {
  .news-teaser-wrapper {
    display: block;
  }
}

@media (--large-viewport) {
  .hero-image {
    height: 90vh;
    max-height: 900px;
  }

}
