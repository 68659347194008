.site-footer-social ul {
  padding: 0;
  list-style: none outside none;
}

.site-footer-social li {
  display: inline-block;
}

.site-footer-social li:nth-child(n+2) {
  margin-left: 1em;
}

.site-footer-newsletter {
  padding: var(--padding-v);
  margin-right: calc((var(--padding-v) + 1) * -1);
  margin-left: calc((var(--padding-v) + 1) * -1);
  border-top: var(--border);
}

@media (--medium-viewport) {
  .site-footer-newsletter {
    grid-row: 1;
    grid-column: 1;

    padding: 0;
    margin: 0;
    border: 0;
  }

  .site-footer-social {
    grid-row: 2;
    grid-column: 1;
  }

  .site-footer-address {
    grid-row: 1;
    grid-column: 2;
  }
  
  .site-footer-contact {
    grid-row: 2;
    grid-column: 2;
  }
}
