.grid {
  display: grid;
  grid-auto-flow: row dense;
  grid-column-gap: var(--padding-v);
  grid-row-gap: var(--padding-v);
}

.grid-gap-0 {
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.grid-column-gap-0 {
  grid-column-gap: 0;
}

.grid-row-gap-0 {
  grid-row-gap: 0;
}

.grid-gap-half {
  grid-column-gap: calc(var(--padding-v) / 2);
  grid-row-gap: calc(var(--padding-v) / 2);
}
.grid-row-gap-eight { grid-row-gap: calc(var(--padding-v) / 8); }
.grid-row-gap-quarter { grid-row-gap: calc(var(--padding-v) / 4); }
.grid-row-gap-half { grid-row-gap: calc(var(--padding-v) / 2); }
.grid-gap-two-thirds {
  grid-column-gap: calc(var(--padding-v) * 2 / 3);
  grid-row-gap: calc(var(--padding-v) * 2 / 3);
}

.grid > *,
.grid > [class*="margin-v"] {
  margin-top: 0;
  margin-bottom: 0;
}

.grid-2x1c { grid-template-columns: 1fr 1fr; }
.grid-2c1c { grid-template-columns: 2fr 1fr; }
.grid-1c2c { grid-template-columns: 1fr 2fr; }
.grid-3x1c { grid-template-columns: repeat(3, 1fr); }
.grid-1c3c { grid-template-columns: 1fr 3fr; }
.grid-3c1c { grid-template-columns: 3fr 1fr; }

.grid-2x1c.grid-rtl > *:nth-child(2n+1),
.grid-2c1c.grid-rtl > *:nth-child(2n+1) {
  grid-column-start: 2;
}
.grid-2x1c.grid-rtl > *:nth-child(2n),
.grid-2c1c.grid-rtl > *:nth-child(2n) {
  grid-column-start: 1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start:  2;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.items-start {
  align-items: start;
}

@media (--medium-viewport) {
  .md\:grid {
    display: grid;
    grid-column-gap: var(--padding-v);
    grid-row-gap: var(--padding-v);
  }

  .md\:grid > *,
  .md\:grid > [class*="margin-v"] {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .md\:grid-gap-0 {
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
  .md\:grid-row-gap-0 {
    grid-row-gap: 0;
  }
  .md\:grid-column-gap-0 {
    grid-column-gap: 0;
  }
  .md\:grid-gap-half {
    grid-column-gap: calc(var(--padding-v) / 2);
    grid-row-gap: calc(var(--padding-v) / 2);
  }
  .md\:grid-row-gap-half { grid-row-gap: calc(var(--padding-v) / 2); }
  .md\:grid-gap-two-thirds {
    grid-column-gap: calc(var(--padding-v) * 2 / 3);
    grid-row-gap: calc(var(--padding-v) * 2 / 3);
  }
  .md\:grid-gap-full { grid-row-gap: var(--padding-v); }

  .md\:grid > *,
  .md\:grid > [class*="margin-v"] {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:grid-2x1c { grid-template-columns: 1fr 1fr; }
  .md\:grid-2c1c { grid-template-columns: 2fr 1fr; }
  .md\:grid-1c2c { grid-template-columns: 1fr 2fr; }
  .md\:grid-3x1c { grid-template-columns: repeat(3, 1fr); }
  .md\:grid-1c3c { grid-template-columns: 1fr 3fr; }

  .md\:grid-2x1c.md\:grid-rtl > *:nth-child(2n+1),
  .md\:grid-2c1c.md\:grid-rtl > *:nth-child(2n+1) {
    grid-column-start: 2;
  }
  .md\:grid-2x1c.md\:grid-rtl > *:nth-child(2n),
  .md\:grid-2c1c.md\:grid-rtl > *:nth-child(2n) {
    grid-column-start: 1;
  }
}

@media (--normal-viewport) {
  .nm\:grid {
    display: grid;
    grid-column-gap: var(--padding-v);
    grid-row-gap: var(--padding-v);
  }

  .nm\:grid-gap-0 {
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
  .nm\:grid-row-gap-0 {
    grid-row-gap: 0;
  }
  .nm\:grid-column-gap-0 {
    grid-column-gap: 0;
  }
  .nm\:grid-gap-half {
    grid-column-gap: calc(var(--padding-v) / 2);
    grid-row-gap: calc(var(--padding-v) / 2);
  }
  .nm\:grid-row-gap-half { grid-row-gap: calc(var(--padding-v) / 2); }
  .nm\:grid-gap-two-thirds {
    grid-column-gap: calc(var(--padding-v) * 2 / 3);
    grid-row-gap: calc(var(--padding-v) * 2 / 3);
  }
  .nm\:grid-gap-full { grid-row-gap: var(--padding-v); }

  .nm\:grid > *,
  .nm\:grid > [class*="margin-v"] {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .nm\:grid-2x1c { grid-template-columns: 1fr 1fr; }
  .nm\:grid-2c1c { grid-template-columns: 2fr 1fr; }
  .nm\:grid-1c2c { grid-template-columns: 1fr 2fr; }
  .nm\:grid-3x1c { grid-template-columns: repeat(3, 1fr); }
  .nm\:grid-1c3c { grid-template-columns: 1fr 3fr; }
  .nm\:grid-4x1c { grid-template-columns: repeat(4, 1fr); }

  .nm\:grid-2x1c.nm\:grid-rtl > *:nth-child(2n+1),
  .nm\:grid-2c1c.nm\:grid-rtl > *:nth-child(2n+1) {
    grid-column-start: 2;
  }
  .nm\:grid-2x1c.nm\:grid-rtl > *:nth-child(2n),
  .nm\:grid-2c1c.nm\:grid-rtl > *:nth-child(2n) {
    grid-column-start: 1;
  }
}

@media (--large-viewport) {
  .lg\:grid {
    display: grid;
    grid-column-gap: var(--padding-v);
    grid-row-gap: var(--padding-v);
  }

  .lg\:grid-gap-0 {
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
  .lg\:grid-row-gap-0 {
    grid-row-gap: 0;
  }
  .lg\:grid-column-gap-0 {
    grid-column-gap: 0;
  }
  .lg\:grid-row-gap-half { grid-row-gap: calc(var(--padding-v) / 2); }
  .lg\:grid-gap-half {
    grid-column-gap: calc(var(--padding-v) / 2);
    grid-row-gap: calc(var(--padding-v) / 2);
  }
  .lg\:grid-gap-two-thirds {
    grid-column-gap: calc(var(--padding-v) * 2 / 3);
    grid-row-gap: calc(var(--padding-v) * 2 / 3);
  }

  .lg\:grid > *,
  .lg\:grid > [class*="margin-v"] {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:grid-2x1c { grid-template-columns: 1fr 1fr; }
  .lg\:grid-2c1c { grid-template-columns: 2fr 1fr; }
  .lg\:grid-1c2c { grid-template-columns: 1fr 2fr; }
  .lg\:grid-3x1c { grid-template-columns: repeat(3, 1fr); }
  .lg\:grid-1c3c { grid-template-columns: 1fr 3fr; }
  .lg\:grid-4x1c { grid-template-columns: repeat(4, 1fr); }
  .lg\:grid-5x1c { grid-template-columns: repeat(5, 1fr); }

  .lg\:grid-2x1c.lg\:grid-rtl > *:nth-child(2n+1),
  .lg\:grid-2c1c.lg\:grid-rtl > *:nth-child(2n+1) {
    grid-column-start: 2;
  }
  .lg\:grid-2x1c.lg\:grid-rtl > *:nth-child(2n),
  .lg\:grid-2c1c.lg\:grid-rtl > *:nth-child(2n) {
    grid-column-start: 1;
  }
}

@media (--extra-large-viewport) {
  .xl\:grid {
    display: grid;
    grid-column-gap: var(--padding-v);
    grid-row-gap: var(--padding-v);
  }

  .xl\:grid-gap-0 {
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
  .xl\:grid-row-gap-0 {
    grid-row-gap: 0;
  }
  .xl\:grid-column-gap-0 {
    grid-column-gap: 0;
  }
  .xl\:grid-row-gap-half { grid-row-gap: calc(var(--padding-v) / 2); }
  .xl\:grid-gap-half {
    grid-column-gap: calc(var(--padding-v) / 2);
    grid-row-gap: calc(var(--padding-v) / 2);
  }
  .xl\:grid-gap-two-thirds {
    grid-column-gap: calc(var(--padding-v) * 2 / 3);
    grid-row-gap: calc(var(--padding-v) * 2 / 3);
  }

  .xl\:grid > *,
  .xl\:grid > [class*="margin-v"] {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:grid-2x1c { grid-template-columns: 1fr 1fr; }
  .xl\:grid-2c1c { grid-template-columns: 2fr 1fr; }
  .xl\:grid-1c2c { grid-template-columns: 1fr 2fr; }
  .xl\:grid-3x1c { grid-template-columns: repeat(3, 1fr); }
  .xl\:grid-1c3c { grid-template-columns: 1fr 3fr; }
  .xl\:grid-4x1c { grid-template-columns: repeat(4, 1fr); }
  .xl\:grid-5x1c { grid-template-columns: repeat(5, 1fr); }
  .xl\:grid-6x1c { grid-template-columns: repeat(6, 1fr); }

  .xl\:grid-2x1c.xl\:grid-rtl > *:nth-child(2n+1),
  .xl\:grid-2c1c.xl\:grid-rtl > *:nth-child(2n+1) {
    grid-column-start: 2;
  }
  .xl\:grid-2x1c.xl\:grid-rtl > *:nth-child(2n),
  .xl\:grid-2c1c.xl\:grid-rtl > *:nth-child(2n) {
    grid-column-start: 1;
  }
}
