body {
  padding: 0;
  margin: 0;
}

[class*="padding-"] {
  box-sizing: border-box;
}

.padding-h {
  padding-right: var(--padding-h);
  padding-left:  var(--padding-h);
}

.padding-v-eight {
  padding-top:    calc(var(--padding-v) / 8);
  padding-bottom: calc(var(--padding-v) / 8);
}

.padding-v-seventh {
  padding-top:    calc(var(--padding-v) / 7);
  padding-bottom: calc(var(--padding-v) / 7);
}

.padding-v-sixth {
  padding-top:    calc(var(--padding-v) / 6);
  padding-bottom: calc(var(--padding-v) / 6);
}

.padding-v-fifth {
  padding-top:    calc(var(--padding-v) / 5);
  padding-bottom: calc(var(--padding-v) / 5);
}

.padding-v-fourth {
  padding-top:    calc(var(--padding-v) / 4);
  padding-bottom: calc(var(--padding-v) / 4);
}

.padding-v-third {
  padding-top:    calc(var(--padding-v) / 3);
  padding-bottom: calc(var(--padding-v) / 3);
}

.padding-v-half {
  padding-top:    calc(var(--padding-v) / 2);
  padding-bottom: calc(var(--padding-v) / 2);
}

.padding-v {
  padding-top:    var(--padding-v);
  padding-bottom: var(--padding-v);
}

.padding-v-double {
  padding-top:    calc(var(--padding-v) * 2);
  padding-bottom: calc(var(--padding-v) * 2);
}

.padding-bottom {
 padding-bottom: var(--padding-v);
}

.margin-h {
  margin-right: var(--padding-h);
  margin-left:  var(--padding-h);
}

.margin-v-eight {
  margin-top:    calc(var(--padding-v) / 8);
  margin-bottom: calc(var(--padding-v) / 8);
}

.margin-v-seventh {
  margin-top:    calc(var(--padding-v) / 7);
  margin-bottom: calc(var(--padding-v) / 7);
}

.margin-v-sixth {
  margin-top:    calc(var(--padding-v) / 6);
  margin-bottom: calc(var(--padding-v) / 6);
}

.margin-v-fifth {
  margin-top:    calc(var(--padding-v) / 5);
  margin-bottom: calc(var(--padding-v) / 5);
}

.margin-v-fourth {
  margin-top:    calc(var(--padding-v) / 4);
  margin-bottom: calc(var(--padding-v) / 4);
}

.margin-v-third {
  margin-top:    calc(var(--padding-v) / 3);
  margin-bottom: calc(var(--padding-v) / 3);
}

.margin-v-half {
  margin-top:    calc(var(--padding-v) / 2);
  margin-bottom: calc(var(--padding-v) / 2);
}

.margin-v {
  margin-top:    var(--padding-v);
  margin-bottom: var(--padding-v);
}

.margin-v-double {
  margin-top:    calc(var(--padding-v) * 2);
  margin-bottom: calc(var(--padding-v) * 2);
}

.margin-bottom {
  margin-bottom: var(--padding-v);
}

.margin-bottom-half {
  margin-bottom: calc(var(--padding-v) / 2);
}

.margin-bottom-double {
  margin-bottom: calc(var(--padding-v) * 2);
}

.margin-0 {
  margin: 0;
}
