.flex {
	display: flex;
}

.flex-grow {
	flex-grow: 1;
}

.flex-grow-0 {
	flex-grow: 0;
}
