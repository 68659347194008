[data-marquee] {
  overflow: hidden;
  white-space: nowrap;
  padding-right: 0;
  padding-left: 0;
  line-height: 1;
}

[data-marquee] * {
  display: inline;
}

.no-touch [data-marquee] a:hover {
  text-decoration: underline;
}

/* 
 * prevent whitespace between children
 * and use padding instead to prevent
 * jumps between cycles
 */
[data-marquee] { font-size: 0; }

.marquee-item {
  padding-right: 1.5em;
  margin: 0;
}

/* optical baseline correction */
.marquee-item {
  position: relative;
  top: -0.025em;
}
