.site-menu {
  position: absolute;
  top: calc(var(--logo-height) + var(--padding-v));
  left: -101vw;
  width: 100vw;
  z-index: 9;
  height: calc(100 * var(--vh) - var(--logo-height) - var(--padding-v));
  background: white;
  opacity: 0;
  line-height: 1;
}

.site-menu-button {
  position: fixed;
  clip: rect(0,0,0,0);
}

.site-menu-button:checked ~ .site-menu {
  left: 0;
  opacity: 1;
}

.site-menu-button:checked ~ .site-menu ul {
  text-align: center;
  border-top: var(--border);
}

.site-menu-button:checked ~ .site-menu li {
  border-bottom: var(--border);
  white-space: nowrap;
}

.site-menu ul {
  padding: 0;
  margin: 0 0 1.5em;
  list-style: none outside none;
}

.site-menu li {
  padding: 0.2em 0 0.3em;
}

.site-menu .is-active {
  text-decoration: underline;
  cursor: default;
}

.site-menu-search {
  margin-top: -1.5em;
  padding: 0.2em 0;
  text-align: center;
  border-bottom: var(--border);
}

.menu-search {
  display: inline-block;
  width: 1em;
  height: 1em;
  text-indent: -9999px;
  overflow: hidden;
  background: transparent url('../img/icon-search.svg') no-repeat 50% 100% / 75% 75%;
}

.menu-is-open {
  position: fixed;
}

@media (--medium-viewport) {
  .site-menu {
    position: static;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: var(--padding-v);
    width: auto;
    height: auto;
    opacity: 1;
    line-height: inherit;
  }

  .site-menu-button,
  .site-menu-label {
    display: none;
  }

  .site-menu ul {
    margin: 0;
  }

  .site-menu li {
    padding: 0;
  }

  .site-menu-secondary {
    display: flex;
    justify-content: space-between;
  }

  .site-menu-search {
    margin: 0;
    padding: 0;
    border: 0;
    text-align: left;
  }
}
