@custom-media --medium-viewport (min-width: 750px);
@custom-media --normal-viewport (min-width: 1000px);
@custom-media --large-viewport (min-width: 1250px);
@custom-media --extra-large-viewport (min-width: 1500px);

:root {
  --white:  #ffffff;
  --black:  #1d1d1b;
  --green:  #006652;
  --red:    #e84232;
  --orange: #f2913e;

  --border: 0.5px solid var(--black);
  --vh: 1vh;
  --padding-h: 1.5rem;
  --padding-v: 2.5rem;

  --fs-smallest: 1.1rem;
  --fs-smaller:  1.2rem;
  --fs-small:    1.3rem;
  --fs-body:     1.5rem;
  --fs-l1:       2.0rem;
  --fs-l2:       2.7rem;
  --fs-l3:       3.6rem;
  --fs-l4:       4.2rem;
  --fs-l5:       4.7rem;
  --fs-menu:       5vh;

  --logo-height: 8rem;
}

@media (--medium-viewport) {
  :root {
    --padding-h: 2.25rem;
    --padding-v: 3.75rem;

    --fs-smallest: 1.2rem;
    --fs-smaller:  1.3rem;
    --fs-small:    1.4rem;
    --fs-body:     1.6rem;
    --fs-l1:       2.1rem;
    --fs-l2:       2.8rem;
    --fs-l3:       3.8rem;
    --fs-l4:       4.4rem;
    --fs-l5:       5.0rem;

    --logo-height: 10rem;
  }
}

@media (--normal-viewport) {
  :root {
    --padding-h: 5rem;
    --padding-v: 5rem;

    --fs-smallest: 1.3rem;
    --fs-smaller:  1.4rem;
    --fs-small:    1.5rem;
    --fs-body:     1.7rem;
    --fs-l1:       2.3rem;
    --fs-l2:       3.0rem;
    --fs-l3:       4.0rem;
    --fs-l4:       4.7rem;
    --fs-l5:       5.4rem;
    }
}

@media (--large-viewport) {
  :root {
    --padding-h: 6.25rem;
    --padding-v: 6.25rem;

    --fs-small:    1.6rem;
    --fs-body:     1.8rem;
    --fs-l1:       2.4rem;
    --fs-l2:       3.2rem;
    --fs-l3:       4.3rem;
    --fs-l4:       5.0rem;
    --fs-l5:       5.7rem;
    }
}

@media (--extra-large-viewport) {
  :root {
    --padding-h: 7.5rem;
    --padding-v: 7.5rem;
  }
}
