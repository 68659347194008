.office-location {
  margin-top: calc(var(--fs-l2) * 1.3 + var(--padding-v) / 3 + 1px);
}

.office-location [data-gmap] {
  height: calc(100vw - 2 * var(--padding-h));
  max-height: 800px;
}

.office-location-button {
  display: inline;
  padding: 0;
  color: inherit;
  border: none;
  background: none;
  cursor: pointer;
  -webkit-appearance: none;
}

.office-location-button:disabled {
  opacity: 1;
  color: inherit;
  text-decoration: underline;
  cursor: default;
}

.office-location-slide {
  width: 100%;
  background: white;
}

.office-location .flickity-prev-next-button {
  --size: calc(var(--fs-l2) + var(--padding-v)); 
  width: var(--size);
  height: var(--size);
  top: calc(-0.55 * var(--fs-l2) + -0.16667 * var(--padding-v));
  left: calc(-0.45 * var(--size));
}

.office-location .flickity-prev-next-button.next {
  left: auto;
  right: calc(-0.45 * var(--size));
}

@media (--medium-viewport) {

  .office-location [data-gmap] {
    height: calc(50vw - var(--padding-h) - 0.5 * var(--padding-v));
  }

}

@media (--normal-viewport) {

  .office-location [data-gmap] {
    height: calc(50vw - var(--padding-h) - 0.5 * var(--padding-v));
  }

  .office-location .flickity-prev-next-button {
    left: calc(-0.55 * var(--size));
  }

  .office-location .flickity-prev-next-button.next {
    right: calc(-0.55 * var(--size));
  }

}
