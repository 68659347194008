.ga_consent {
	position: fixed;
	bottom: 0;
	width: 100%;
	max-height: 0;
	overflow: hidden;
	background: var(--black);
	color: white;
	transition: max-height .2s ease-in-out;
}

.ga_consent_content {
	box-sizing: border-box;
	width: 100%;
}

.ga_consent.ga_consent_show {
	max-height: 50vh;
}

.ga_consent_accept {
	display: inline-block;
	padding: 0.2em 0.5em;
	margin-right: 1em;
	background: white;
	color: var(--black);
	text-decoration: none;
	font-size: 1.125em;
	font-weight: 400;
}

.ga_consent_refuse {
	font-size: 0.875em;
	text-decoration: underline;
}

@media (--medium-viewport) {
	.ga_consent_content {
		display: flex;
		align-items: flex-end;
	}
	
	.ga_consent_buttons {
		display: flex;
		align-items: baseline;
		flex-shrink: 0;
		margin: 0 0 0.2em 2em;
	}
}
