.project-filter {
  grid-column: 1 / -1;
  padding-top: 0; 
  padding-right: var(--padding-v);
  padding-bottom: calc(var(--padding-v) / 4);
  padding-left: var(--padding-v);
  margin-top: calc(-0.75 * var(--padding-v));
  margin-right: calc(-1 * var(--padding-v));
  margin-bottom: calc(var(--padding-v) / 4);
  margin-left: calc(-1 * var(--padding-v));
  border-bottom: var(--border);
}

.project-filter-label {
  white-space: nowrap;
}

.project-filter-label,
.project-filter label {
  margin: 0 0.5em 0 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.project-filter input {
  position: absolute;
  visibility: hidden;
}

.project-filter label {
  opacity: 0.5;
  cursor: pointer;
}

.project-filter :checked + label {
  text-decoration: underline;
  opacity: 1;
}

@media (--medium-viewport) {
  .project-filter {
    display: flex;
  }

  .project-filter-label,
  .project-filter-menu {
    display: inline-block;
  }

  .project-filter-label,
  .project-filter label {
    margin: 0 0.75em 0 0;
  }
}
