.site-wrapper {
  background: white;
  color: var(--black);
}

.c-white {
  color: white;
  border-color: white;
}

.c-b-bis,
.c-red {
  color: var(--red);
  border-color: var(--red);
}

.c-b-city,
.c-green {
  color: var(--green);
  border-color: var(--green);
}

.c-b-juxta,
.c-orange {
  color: var(--orange);
  border-color: var(--orange);
}
