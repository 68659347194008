/* push site footer to the bottom */
.site-body {
  width: 100%;
}

.site-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  max-width: 100%;
  min-height: calc(100 * var(--vh));
}

.site-footer {
  box-sizing: border-box;
  width: 100%;
}

.border-top { border-top: var(--border); }
.border-bottom { border-bottom: var(--border); }

.no-border { border: 0; }

@media (--medium-viewport) {
  .md\:border-top    { border-top: var(--border); }
  .md\:border-bottom { border-top: var(--border); }
  .md\:no-border     { border: 0; }
}

@media (--normal-viewport) {
  .nm\:border-top    { border-top: var(--border); }
  .nm\:border-bottom { border-top: var(--border); }
  .nm\:no-border     { border: 0; }
}

@media (--large-viewport) {
  .lg\:border-top    { border-top: var(--border); }
  .lg\:border-bottom { border-top: var(--border); }
  .lg\:no-border     { border: 0; }
}

@media (--extra-large-viewport) {
  .xl\:border-top    { border-top: var(--border); }
  .xl\:border-bottom { border-top: var(--border); }
  .xl\:no-border     { border: 0; }
}

/* Sorry extra large screens */
@media (min-width: 2000px) {

  .site-wrapper {
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
  }
}
