.teaser-grid-extra > *:nth-child(n+3) {
  display: none;
}

@media (--medium-viewport) {
  .teaser-grid-extra > *:nth-child(n+3) {
    display: block;
  }

  .teaser-grid-extra > *:nth-child(n+4) {
    display: none;
  }
}

@media (--normal-viewport) {
  .teaser-grid-extra > *:nth-child(n+4) {
    display: block;
  }

  .teaser-grid-extra > *:nth-child(n+5) {
    display: none;
  }
}

@media (--large-viewport) {
  .teaser-grid-extra > *:nth-child(n+5) {
    display: block;
  }

  .teaser-grid-extra > *:nth-child(n+6) {
    display: none;
  }
}

@media (--extra-large-viewport) {
  .teaser-grid-extra > *:nth-child(n+6) {
    display: block;
  }
}
