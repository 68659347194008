.article-teaser-image-wrapper {
  margin: 0;
}

.article-teaser-caption {
  max-width: 100%;
}

.no-touch .article-teaser a {
  text-decoration: none;
}

.no-touch .article-teaser a:hover {
  text-decoration: underline;
}

[class*="oembed-wrapper"] {
  position: relative;
}

.oembed-play-icon {
  position: absolute;
  display: block;
  top: calc(50% - 3.3rem);
  left: calc(50% - 5rem);
  width: 10rem;
  height: auto;
  filter: drop-shadow(0 0 0.3rem #000);
}

@media (--medium-viewport) {
  .article-cluster {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .article-cluster > * {
    max-width: calc(50% - var(--padding-v) / 2);
  }

  .article-cluster.balanced > * {
    max-width: none;
  }
}

@media (--normal-viewport) {
  .article-cluster {
/*    max-width: 1250px; */
    margin-left: auto;
    margin-right: auto;
  }

  .article-cluster > * {
    max-width: calc(33.333% - var(--padding-v) * 4 / 9);
  }
}
