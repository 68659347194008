/* set pre-JS for initial collapse transition on load */
[data-accordion] {
  max-height: 15000px;
  transition: max-height .5s;
}

.ui-accordion {
  /* button font size * line height + padding + 1px for the border */
  position: relative;
}

.ui-accordion > *:nth-child(3) {
  overflow: hidden;
  max-height: 0;
  transition: max-height .25s;
}

.ui-accordion.is-open > *:nth-child(3) {
  max-height: 5000px;
  transition: max-height .5s;
}

.ui-accordion-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: calc(var(--padding-v) / 6) var(--padding-h);
  color: inherit;
  border: 0;
  background: transparent;
  font-family: inherit;
  font-size: var(--fs-l2);
  font-weight: inherit;
  text-align: left;
  cursor: pointer;
}

.ui-accordion .ui-accordion-button::after {
  content: '+';
}

.ui-accordion.is-open .ui-accordion-button::after {
  content: '–';
}

.ui-accordion-title {
  margin-left: 1em;
}

.grid > .ui-accordion-title {
  margin-left: 0;
}

/* fade in accordion */
[data-accordion] > * {
  opacity: 0;
}

[data-accordion].ui-accordion > * {
  opacity: 1;
  transition: opacity .5s .5s;
}

@media (min-width: 750px) {
  .md\:grid > .ui-accordion-title {
    margin-left: 0;
  }
}
