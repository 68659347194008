:root { --unica: neue-haas-unica, sans-serif; }

html,
.f-unica { font-family: var(--unica); }

html,
.fw-300 { font-weight: 300; }

h1, h2, h3, h4, h5, h6, strong, .fw-400 { font-weight: 400; }

html { line-height: 1.3; }

ul:not([class*="menu"]) { padding-left: 0; }
ul:not([class*="menu"]) li {
  position: relative;
  padding-left: 1em;
  list-style: none outside;
}
ul:not([class*="menu"]) li::before {
  position: absolute;
  left: 0;
  content: '◦ ';
}

.align-center { text-align: center; }
.align-right { text-align: right; }

.no-underline { text-decoration: none; }

.no-touch a:hover .hover\:underline,
.no-touch .hover\:underline:hover {text-decoration: underline; }

body,
h6, h5, .fs-body { font-size: var(--fs-body); }
h4, .fs-h4 { font-size: var(--fs-l1); }
h3, .fs-h3 { font-size: var(--fs-l2); }
h2, .fs-h2 { font-size: var(--fs-l3); }
h1, .fs-h1 { font-size: var(--fs-l5); }
.fs-small    { font-size: var(--fs-small); }
.fs-smaller  { font-size: var(--fs-smaller); }
.fs-smallest { font-size: var(--fs-smallest); }
.fs-menu     { font-size: var(--fs-menu); }

@media (--medium-viewport) {
  .fs-menu { font-size: var(--fs-body); }
  .md\:fs-body { font-size: var(--fs-body); }
  .md\:fs-h4 { font-size: var(--fs-l1); }
  .md\:fs-h3 { font-size: var(--fs-l2); }
  .md\:fs-h2 { font-size: var(--fs-l3); }
  .md\:fs-h1 { font-size: var(--fs-l5); }
}

@media (--normal-viewport) {
  .nm\:fs-body { font-size: var(--fs-body); }
  .nm\:fs-h4 { font-size: var(--fs-l1); }
  .nm\:fs-h3 { font-size: var(--fs-l2); }
  .nm\:fs-h2 { font-size: var(--fs-l3); }
  .nm\:fs-h1 { font-size: var(--fs-l5); }
}

@media (--large-viewport) {
  .fs-menu { font-size: var(--fs-l1); }
  .lg\:fs-body { font-size: var(--fs-body); }
  .lg\:fs-h4 { font-size: var(--fs-l1); }
  .lg\:fs-h3 { font-size: var(--fs-l2); }
  .lg\:fs-h2 { font-size: var(--fs-l3); }
  .lg\:fs-h1 { font-size: var(--fs-l5); }
}

@media (--extra-large-viewport) {
  .xl\:fs-body { font-size: var(--fs-body); }
  .xl\:fs-h4 { font-size: var(--fs-l1); }
  .xl\:fs-h3 { font-size: var(--fs-l2); }
  .xl\:fs-h2 { font-size: var(--fs-l3); }
  .xl\:fs-h1 { font-size: var(--fs-l5); }
}
