.instagram-feed {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s, opacity 1s ease-in 0.5s;
}

.instagram-feed.loaded {
  max-height: 500px;
  opacity: 1;
}

.ig-caption {
  display: flex;
  justify-content: space-between;
}
