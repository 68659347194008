.hero-wrapper {
  position: relative;
}

.hero-caption {
  position: absolute;
  top: 0; 
  right: 0;
}

.hero-caption.c-black {
  text-shadow: 0 0 0.2em rgba(255, 255, 255, 0.75);
}

.hero-caption.c-white {
  text-shadow: 0 0 0.2em rgba(0, 0, 0, 0.5);
}

.article-full-intro {
  margin-bottom: 0.75em;
}
