.site-logo {
  display: block;
  width: auto;
  height: var(--logo-height);
}

.site-logo img {
  display: block;
  width: auto;
  height: 100%;
}

.site-header {
  background: white;
  border-bottom: var(--border);
}
