a:not([class]) { text-decoration: underline; }

a,
a[href*="tel:"] {
  color: inherit;
  text-decoration: none;
}

/* don't break e-mail addresses */
a[href*="mailto"] { white-space: nowrap; }
