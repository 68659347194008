.project-grid,
.project-teaser {
  overflow: hidden;
  min-width: 0;
}

.project-teaser-wrapper {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 100%;
}

.project-teaser-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: block;
  width: 100%;
  padding-top: 100%;
  outline: var(--border);
  outline-offset: -1px;
}

.project-teaser-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: transform .25s, opacity 1s;
}

.project-teaser-image[data-loaded] {
  opacity: 1;
}

.no-touch .project-teaser-image:hover {
  transform: scale(1.25);
}

.project-teaser-caption {
  margin-top: 0.5em;
  hyphens: auto;
}
