p, ul, ol, blockquote, table {
  margin: 0.75em 0 0;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child,
ul:first-child,
ol:first-child,
blockquote:first-child,
table:first-child { margin-top: 0; }

.align-with-h4 {
  margin-top: 0.3333em;
}

h5,
h6 { margin-bottom: 0; }
