[data-search-button]:focus {
	outline: 0;
	border: 0;
}

[data-search-form] {
	box-sizing: border-box;
	max-height: 10rem;
	overflow: hidden;
	transition: max-height .5s ease-in-out;
}

[data-search-form][collapsed] {
	opacity: 0;
	max-height: 0;	transition: max-height .5s ease-in-out, opacity .5s ease-in-out;
}
