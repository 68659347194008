.form-x {
  box-sizing: border-box;
  font-family: var(--unica);
  font-weight: 300;
	font-size: inherit;
  border: 0;
}

.search-form-x,
.search-form-x::placeholder,
.form-x[disabled="true"] {
	color: #bbb;
	opacity: 1;
}

.search-form-x {
  padding: 0;
}

.newsletter-form-x {
	height: 2em;
  line-height: 2em;
	border-bottom: var(--border);
}

.form-x + button::after {
  content: ' \2192';
}

.form-x + button[disabled] {
  display: none;
}

.form-x:focus {
  border: 0;
  outline: 0;
}

.search-form-x:focus {
  color: black;
}

.newsletter-form-x:focus {
  padding: 0 0.5em 0.25em;
  color: white;
  background: var(--black);
}

.form-x + button {
  display: inline-block;
  padding: 0;
  cursor: pointer;
  font-family: var(--unica);
  font-weight: 300;
  font-size: inherit;
  border: 0;
  background: none;
}

.search-form-x + button {
  margin: 0 0 0 1em;
}

.newsletter-form-x + button {
  margin: 0.75em 0;
}

.ff-form-success {
  margin: 10px 0;
}

@media (--medium-viewport) {
  .newsletter-form-x {
    display: inline-block;
    width: 100%;
    max-width: 35rem;
  }
}

@media (--normal-viewport) {
  .newsletter-form-x + button {
    margin: 0 0 0 1em;
  }
}
