.carousel-slide {
  width: 100%;
}

.carousel-slide:nth-child(n+2) {
  visibility: hidden;
  width: 100%;
}

.carousel-slide-embedded .carousel-image {
  max-width: 800px;
  margin: 0 auto;
}

.flickity-enabled .carousel-slide {
  visibility: visible;
}

.carousel-caption {
  margin-top: 0.5em;
}

.carousel-changing .carousel-caption {
  opacity: 0;
}

.carousel-settled .carousel-caption {
  opacity: 1;
  transition: opacity 0.25s;
}

.flickity-button,
.flickity-button:hover {
  background: transparent;
}

.flickity-prev-next-button {
  width: var(--padding-v);
  height: var(--padding-v);
}

.carousel-counter {
  position: absolute;
  right: var(--padding-v);
  bottom: 0;
}
