.site-menu-label {
  --hamburger-spacing: 1rem;
  --hamburger-bar-height: 2px;
  --hamburger-width: 3rem;
  --hamburger-height: calc(2 * var(--hamburger-spacing) + 3 * var(--hamburger-bar-height));

  position: absolute;
  top: 0;
  right: 0;
  width: var(--hamburger-width);
  height: var(--hamburger-height);
  cursor: pointer;
}

.site-menu-label span, 
.site-menu-label span:before, 
.site-menu-label span:after {
  position: absolute;
  left: 0;
  display: block;
  height: var(--hamburger-bar-height);
  width: var(--hamburger-width);
  background: var(--black);
  content: '';
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

.site-menu-label span {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.site-menu-label span::before {
  top: calc((var(--hamburger-spacing) + var(--hamburger-bar-height)) * -2);
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
              transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.site-menu-label span::after {
  top: calc((var(--hamburger-spacing) + var(--hamburger-bar-height)) * -1);
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
              opacity 0.1s linear;
}

.site-menu-button:checked + .site-menu-label span {
  transform: translate3d(0, calc((var(--hamburger-spacing) + var(--hamburger-bar-height)) * -1), 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.site-menu-button:checked + .site-menu-label span::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
              opacity 0.1s 0.22s linear;
}

.site-menu-button:checked + .site-menu-label span::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
              transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
